/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import React, { Component } from 'react'
// eslint-disable-next-line no-unused-vars
import ReactPlayer from 'react-player' 


export default function Aboutusection() {
  return (
  <> 
  <section>
  <div className="mx-auto max-w-screen-xl px-4 py-2 sm:px-6  lg:px-3 lg:py-3 lg:mb-3">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div className="relative h-64 overflow-hidden rounded-lg sm:h-8 lg:order-last lg:h-full">
     
      <div className='mr-5 player-wrapper'>
      <img
            className="object-contain w-full h-56 rounded shadow-lg sm:h-96"
            src="./assets/media/socialabout.webp"
            alt=""
          />
        </div>

      </div>

      <div className="ml-6 mt-2 ">
      <h5 className="mr-5 mb-4 text-2xl font-extrabold leading-none">
          About  <a className="text-orange-500" >  Mahakal Socialwelfare Society </a>
 
          </h5>

        

        <p className="mt-4 text-gray-600">
      
Social welfare societies are non-profit organizations that work to improve the lives of individuals and communities by addressing social problems and promoting social justice. They play a vital role in supporting those in need and building a more equitable society.<br></br>
 
 This can include job training, financial literacy education, and counseling.
Advocate for change: Social welfare societies work to influence policies and legislation that address the root causes of poverty, inequality, and other social problems.
 
 
        </p>
     
<a
  class="mt-2 inline-flex items-center gap-2 rounded border border-indigo-600 bg-orange-600 px-8 
  py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Contact"
>
  <span class="text-sm font-medium"> 
Contact Us Now 
      </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="https://ik.imagekit.io/hickter/Hickter_Projects/MSWS/OTHERS/socialabout.png?updatedAt=1708347173488"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
 
      </div>
    </div> 
  </div>

  <div className="px-4 py-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:mb-[10px]">
      <div className="grid gap-10 lg:grid-cols-2">
      <div>
          <img
            className="object-contain w-full h-56 rounded shadow-lg sm:h-96"
            src="./assets/media/mission.webp"
            alt=""
          />
        </div>
        <div className="lg:pr-10">
          <a
            href="/"
            aria-label="Go Home"
            title="Logo"
            className="inline-block mb-5"
          >
           
          </a>
          <h5 className="ml-6 mb-4 text-2xl font-extrabold leading-none">
          Our   <a className="text-orange-500" >  Mission </a>
 
          </h5>
          <p className="ml-6 mt-2 mb-6 text-gray-900">
          Society missions include:
<br></br>
<b>  1. Addressing social issues: </b>Their mission often focuses on tackling specific social problems like poverty, hunger, homelessness, lack of access to education.
<br></br>
<b> 2. Promoting social justice and equity: </b>They aim to create a more just and equitable society where everyone has the opportunity to thrive, regardless of their background or circumstances.
<br></br>
<b> 3. Empowering individuals and communities:</b> Their mission often emphasizes empowering individuals and communities to become self-reliant, develop their skills, and advocate for their rights. </p>
          <hr className="mb-5 border-gray-300" />
          
        </div>
       
      </div>
    </div>

</section>
  </>
  )
}

 