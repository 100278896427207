/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';
import Navbarsection from '../views/Home/Navbarsection';
import Footersection from '../views/Home/Footersection';
import Feature from '../views/Home/Feature';

export default function Our_services_hickter() {
  return (
   <>
   <Navbarsection/> 
<Feature/> 
      
   <Footersection/> 
 

 
  </>
  )
}
  
 