/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'
import ReactPlayer from 'react-player' 

export default function Blog1() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-left  font-bold lg:text-[18px]  sm:text-3xl justify-self-auto " >  What " Mahakal Social Welfare Society "  Can do For You ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
Social welfare societies can do a multitude of things for individuals, depending on the specific organization's focus and resources.
Promoting Health and Well-being:
<br></br>
Facilitating access to healthcare services: This can involve connecting individuals with clinics, hospitals, or insurance programs.<br></br>
<b> Providing mental health support: </b> This may include counseling services, support groups, or referrals to mental health professionals.<br></br>
<b> Empowering Individuals:</b>

Offering educational opportunities: This could involve scholarships, tutoring programs, or adult education classes.
Equipping individuals with job skills: <br></br>This may include vocational training, job placement assistance.


            </p>
          </div>
          
        </div>
        <div>
        <div className='mr-3 player-wrapper'>
        <div>
          <img
            className="object-contain w-full h-56 rounded shadow-lg sm:h-96"
            src="./assets/blog/1.webp"
            alt=""
          />
        </div>
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
