/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter,BsLinkedin,BsYoutube,BsSkype,BsPinterest,BsTelegram } from 'react-icons/bs';

function Footersection() {
  return (
   <>
 
    <Footer className='bg-black text-white bg-opacity-80' >
      <div className="text-white w-full">
        <div className="grid w-full grid-cols-2 gap-8 px-6 py-8 md:grid-cols-4">
          <div>
            <Footer.Title className="text-white" title="Organisation" />
            <Footer.LinkGroup col>
              <Footer.Link className="text-yellow-200" href="/About">About</Footer.Link>
              <Footer.Link className="text-yellow-200" href="/Jobs">Careers</Footer.Link>
              <Footer.Link className="text-yellow-200" href="/Contact">Contact Us </Footer.Link>
              <Footer.Link className="text-yellow-200" href="/Blog">Blog</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className="text-white" title="help center" />
            <Footer.LinkGroup col>
              <Footer.Link className="text-yellow-200" href="mailto:contact@mahakalsocialwelfaresociety.com">Support </Footer.Link> 
              <Footer.Link className="text-yellow-200" href="/Contact">Contact Us</Footer.Link>
              <Footer.Link className="text-yellow-200" href="mailto:contact@hickter.in" > Mail </Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className="text-white"  title="legal" />
            <Footer.LinkGroup col>
              <Footer.Link className="text-yellow-200" href="/Privacy">Privacy Policy</Footer.Link> 
              <Footer.Link className="text-yellow-200" href="/Terms">Terms &amp; Conditions</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className="text-white"  title="Quick Links  " />
            <Footer.LinkGroup col>
              
              <Footer.Link className="text-yellow-200" href="/Our_services_hickter"> Our Services </Footer.Link>  
              <Footer.Link className="text-yellow-200" href="/whyus"> Why Choose Us ? </Footer.Link>   
            </Footer.LinkGroup>
          </div>
        </div>
        <div className="w-full bg-orange-500 bg-opacity-100 px-4 py-6 sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright className='text-white' href="mailto:contact@mahakalsocialwelfaresociety.com"  target="_blank" by="Mahakal Social Welfare Society " year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon className='text-white' href="https://www.facebook.com//" icon={BsFacebook} />
            <Footer.Icon className='text-white' href="https://www.instagram.com//" icon={BsInstagram} />
            <Footer.Icon className='text-white' href="https://twitter.com/" icon={BsTwitter} />
            <Footer.Icon className='text-white' href="https://www.youtube.com/channel/UC_G8P3qpxRzJTn27nWZ6lDQ" icon={BsYoutube} />
            <Footer.Icon className='text-white' href="https://in.linkedin.com/company/" icon={BsLinkedin} />
            <Footer.Icon className='text-white' href="https://t.me/" icon={BsTelegram} />
            <Footer.Icon className='text-white' href="https://in.pinterest.com//" icon={BsPinterest} />
            <Footer.Icon className='text-white' href="https://join.skype.com/invite/" icon={BsSkype} />
          </div>
        </div>
      </div>
    </Footer>
  

   </>
  )
}

export default Footersection