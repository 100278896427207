/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';
import Navbarsection from './Navbarsection';
import Footersection from './Footersection';

export default function Whyus() {
  return (
   <>
   <Navbarsection/>
 
   <section className="items-center flex flex-col" >
    
  <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-2"><h2 class="w-1/2 p-4 mx-auto text-center font-bold text-[20px]"> Why Choose Us </h2>
      <div className="grid gap-5 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">


        <div className="px-12 text-left sm:px-0">
        <Card className="hover:scale-[1.02] focus:scale-[1] hover:text-orange-600 " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-left justify-left w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M11.7 2.805a.75.75 0 0 1 .6 0A60.65 60.65 0 0 1 22.83 8.72a.75.75 0 0 1-.231 1.337 49.948 49.948 0 0 0-9.902 3.912l-.003.002c-.114.06-.227.119-.34.18a.75.75 0 0 1-.707 0A50.88 50.88 0 0 0 7.5 12.173v-.224c0-.131.067-.248.172-.311a54.615 54.615 0 0 1 4.653-2.52.75.75 0 0 0-.65-1.352 56.123 56.123 0 0 0-4.78 2.589 1.858 1.858 0 0 0-.859 1.228 49.803 49.803 0 0 0-4.634-1.527.75.75 0 0 1-.231-1.337A60.653 60.653 0 0 1 11.7 2.805Z" />
  <path d="M13.06 15.473a48.45 48.45 0 0 1 7.666-3.282c.134 1.414.22 2.843.255 4.284a.75.75 0 0 1-.46.711 47.87 47.87 0 0 0-8.105 4.342.75.75 0 0 1-.832 0 47.87 47.87 0 0 0-8.104-4.342.75.75 0 0 1-.461-.71c.035-1.442.121-2.87.255-4.286.921.304 1.83.634 2.726.99v1.27a1.5 1.5 0 0 0-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.66a6.727 6.727 0 0 0 .551-1.607 1.5 1.5 0 0 0 .14-2.67v-.645a48.549 48.549 0 0 1 3.44 1.667 2.25 2.25 0 0 0 2.12 0Z" />
  <path d="M4.462 19.462c.42-.419.753-.89 1-1.395.453.214.902.435 1.347.662a6.742 6.742 0 0 1-1.286 1.794.75.75 0 0 1-1.06-1.06Z" />
</svg>

          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
          Expertise and experience 
          </h6>
          <div className="mb-2 text-gray-700">
          These organizations specialize in addressing specific social issues, knowledge and experience. They can provide tailored support that  individual needs and challenges.
          </div>
          </Card>
        </div>

        <div className="px-12 text-left sm:px-0">
        <Card className="hover:scale-[1.02] focus:scale-[1] hover:text-orange-600 " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M5.337 21.718a6.707 6.707 0 0 1-.533-.074.75.75 0 0 1-.44-1.223 3.73 3.73 0 0 0 .814-1.686c.023-.115-.022-.317-.254-.543C3.274 16.587 2.25 14.41 2.25 12c0-5.03 4.428-9 9.75-9s9.75 3.97 9.75 9c0 5.03-4.428 9-9.75 9-.833 0-1.643-.097-2.417-.279a6.721 6.721 0 0 1-4.246.997Z" clip-rule="evenodd" />
</svg>

          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
          Comprehensive approach:
          </h6>
          <div className="mb-2 text-gray-700">
          Social welfare societies go beyond immediate relief,   causes of problems. They offer holistic support  counseling, skills development, and access to resources for long-term improvement.
          </div>
          </Card>
        </div>


        <div className="px-12 text-left sm:px-0">
        <Card className="hover:scale-[1.02] focus:scale-[1] hover:text-orange-600 " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M19.006 3.705a.75.75 0 1 0-.512-1.41L6 6.838V3a.75.75 0 0 0-.75-.75h-1.5A.75.75 0 0 0 3 3v4.93l-1.006.365a.75.75 0 0 0 .512 1.41l16.5-6Z" />
  <path fill-rule="evenodd" d="M3.019 11.114 18 5.667v3.421l4.006 1.457a.75.75 0 1 1-.512 1.41l-.494-.18v8.475h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3v-9.129l.019-.007ZM18 20.25v-9.566l1.5.546v9.02H18Zm-9-6a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75V15a.75.75 0 0 0-.75-.75H9Z" clip-rule="evenodd" />
</svg>

          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
          Building self-reliance: 
          </h6>
          <div className="mb-2 text-gray-700">
          These organizations equip individuals with the skills and resources needed to overcome challenges and achieve self-reliance, fostering a sense of agency and dignity.
          </div>
          </Card>
        </div>



        <div className="px-12 text-left sm:px-0">
        <Card className="hover:scale-[1.02] focus:scale-[1] hover:text-orange-600 " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-left justify-left w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M12.516 2.17a.75.75 0 0 0-1.032 0 11.209 11.209 0 0 1-7.877 3.08.75.75 0 0 0-.722.515A12.74 12.74 0 0 0 2.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 0 0 .374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 0 0-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08Zm3.094 8.016a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
</svg>

          </div>
          <h6 className="mb-2 text-sm font-bold leading-5 tracking-wider uppercase">
          Advocacy for systemic change: 
          </h6>
          <div className="mb-2 text-gray-700">
          They not only support individuals but also advocate for broader social change. They work towards addressing systemic issues that supportive environment for everyone.
          </div>
          </Card>
        </div>


      </div>
    </div>
</section>

<Footersection/> 
  </>
  )
}
 