/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'


export default function Blog2() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-left  font-bold lg:text-[16px]  sm:text-3xl justify-self-auto " > Why to Choose "Mahakal Social Welfare Society" for Your Well Life ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            Social welfare societies can do a multitude of things for individuals, depending on the specific organization's focus and resources. Here are some broad categories of support they can offer:
<br></br><b>Meeting Basic Needs: </b>


Providing food, shelter, clothing, and other essential resources: This can be crucial for individuals and families facing poverty, homelessness, or other hardships. 
<br></br><b> Promoting Health and Well-being:</b>


Facilitating access to healthcare services: This can involve connecting individuals with clinics, hospitals, or insurance programs. professionals.
Empowering Individuals:
<br></br><b>Offering educational opportunities: </b>
 This could involve scholarships, tutoring programs, or adult education classes.
Equipping individuals with job skills: This may include vocational training, job placement assistance, or workshops on resume writing and interview skills. 
  </p>
   
     </p>
          </div>
          
        </div>
        <div>
        <div className='player-wrapper'>
        <img src="./assets/blog/2.webp" className="h-210 w-full 
    rounded --300" alt="" /> 
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
