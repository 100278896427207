/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'

export default function Donation() {
  return (
   <><Navbarsection /><p> Comming Soon  </p>
   <Footersection />
   
   </>
  )
}
