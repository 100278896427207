/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
import { Accordion } from 'flowbite-react';

export default function Faqsection() {
  return (
   <>
   <br></br>
   <div class="mx-auto">
      <div  class="mb-[2px] text-gray-600 body-font  ">
      <h3 class="text-center text-2xl font-bold sm:text-3xl"> FAQ  </h3>
        </div>
      </div>

  <Accordion className="p-4" >
      <Accordion.Panel >
        <Accordion.Title className=" text-pink-600 font-bold" >What is "Mahakal Social Welfare Society" ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
    <p>  Mahakal Social Welfare Society.</p>     
 
    Addressing diverse needs and contributing significantly to societal well-being.

Here are some key roles and functions of social welfare societies:
<br></br>
Direct Service Provision: Societies directly provide essential services to vulnerable populations, such as food assistance, shelter, healthcare, education, and vocational training. They bridge the gap for individuals and families struggling with financial hardship, homelessness, disability, or social exclusion.
Advocacy and Awareness: These organizations raise awareness about critical social issues, advocate for policy changes, and influence decision-making processes. They act as a voice for the marginalized, ensuring their needs are heard and addressed.<br></br>
Community Development: Social welfare societies often work at the grassroots level, fostering community engagement and empowerment. They collaborate with local stakeholders to identify and address specific community needs, promoting sustainable development and social cohesion.
Research and Capacity Building: These organizations conduct research to understand the root causes of social problems and develop effective interventions. They also contribute to capacity building by training social workers and volunteers, enhancing their skills and knowledge to better serve the community.<br></br>
The impact of social welfare societies is multifaceted:

Improved quality of life: By providing essential services and addressing social issues, these organizations directly contribute to an improved quality of life for individuals and families.
Empowerment and self-reliance: They equip individuals with the resources and skills needed to overcome challenges and achieve self-reliance, fostering a sense of agency and dignity.<br></br>
Stronger communities: By addressing social inequities and promoting social cohesion, these organizations contribute to building stronger and more resilient communities.
Challenges faced by social welfare societies:
<br></br>
Limited resources: These organizations often operate with limited funding and rely heavily on volunteers and donations.
Complex social issues: The social problems they address are often multifaceted and require long-term solutions.
Policy and systemic barriers: Navigating complex policies and advocating for systemic change can be challenging.
Despite these challenges, social welfare societies play an indispensable role in building a more just and equitable society. <br></br>Their dedication, compassion, and tireless efforts contribute significantly to the well-being of individuals, families, and communities as a whole.
          </p>
           
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className=" text-pink-600 font-bold" > Why to Choose "Mahakal Social Welfare Society" ?  </Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          compelling reasons to consider them for your needs:
<br></br>
1. Expertise and Experience: Social welfare organizations specialize in addressing specific social issues and challenges. They possess extensive knowledge and experience in navigating complex situations and providing tailored support. Their staff often comprises qualified professionals, social workers, and volunteers trained to offer effective assistance.
<br></br>
2. Comprehensive Approach: These organizations go beyond simply providing immediate relief. They often focus on addressing the root causes of problems, offering holistic support that encompasses various aspects of an individual's life. This may include counseling, skills development, access to resources, and long-term solutions for sustainable improvement.
<br></br>
3. Confidentiality and Respect: Social welfare organizations prioritize confidentiality and treat individuals with dignity and respect. They understand the sensitive nature of seeking help and create a safe space for individuals to share their struggles without judgment.
<br></br>
4. Community Connection: Choosing a local social welfare organization allows you to connect with your community and access support networks. This fosters a sense of belonging and reduces feelings of isolation, which can be crucial during challenging times.
<br></br>
5. Empowerment and Advocacy: Social welfare organizations not only provide support to individuals but also advocate for systemic change. They work towards addressing broader social issues that contribute to individual struggles, aiming to create a more equitable and supportive environment for everyone.

Choosing a social welfare organization for support goes beyond simply receiving assistance. It involves connecting with a dedicated group working towards positive social change and building a brighter future for individuals and communities.
<br></br>
It's important to remember that not all social welfare organizations are the same. Researching different organizations, understanding their specific services and expertise, and finding one that aligns with your needs is crucial for maximizing the benefit and impact of their support.
          </p>
        
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>

        <Accordion.Title className=" text-pink-600 font-bold" >  How to Contact Mahakal Soical Welfare Society For Support  ?  </Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Just   <a className='font-bold text-[16px] text-green-700' href='/Contact'>
          Contact Here  </a>Fill The Form & Get Update Soon For Process. 
         
          </p>
             
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>

<Accordion.Title className=" text-pink-600 font-bold" > How this a Social Welfare Society Can Help You  ?  </Accordion.Title>
<Accordion.Content>
  <p className="mb-2 text-gray-500 dark:text-gray-400">Social welfare societies play a vital role in supporting individuals and communities in various ways. Here are some key examples of how they help humans:

Direct assistance:
<br></br>
Meeting basic needs: Providing food, shelter, clothing, and other essential resources to individuals and families struggling with poverty, homelessness, or other hardships.
Healthcare access: Facilitating access to healthcare services for those who cannot afford them or lack adequate insurance coverage.
Education support: Offering scholarships, educational resources, and mentorship programs to help individuals pursue education and improve their future prospects.
Empowerment and capacity building:
<br></br>
Skills training: Providing training programs to equip individuals with skills necessary for employment and self-reliance, promoting economic empowerment.
Counseling and mental health support: Offering counseling services to individuals facing emotional or mental health challenges, promoting well-being and resilience.
Advocacy and legal support: Advocating for the rights of marginalized groups and individuals, ensuring access to justice and fair treatment.
Community development and social change:
<br></br>
Addressing social issues: Working to address root causes of social problems like poverty, discrimination, and lack of access to resources, promoting social justice and equity.
Community organizing and mobilization: Empowering communities to identify their needs, advocate for change, and participate in decision-making processes.
Disaster relief and emergency response: Providing essential aid and support during natural disasters and other emergencies, helping communities recover and rebuild.
Here are some specific examples of how social welfare societies can help individuals:
<br></br>
A single mother struggling to afford childcare might receive assistance from a society that provides subsidized childcare services.<br></br>
A young man facing unemployment might benefit from a society's job training program and find meaningful employment.<br></br>
An elderly individual living alone might receive regular check-ins and support from a society's volunteers, ensuring their well-being and reducing social isolation.
The impact of social welfare societies extends beyond individual assistance. They contribute to building stronger communities by:<br></br>

Promoting social cohesion and reducing inequality.
Empowering individuals and communities to become self-reliant and advocate for their rights.<br></br>
Creating a more just and equitable society for all.
In conclusion, social welfare societies play a crucial role in human well-being by providing direct assistance, empowering individuals, fostering community development, and advocating for social change. Their diverse efforts contribute significantly to building a more supportive and equitable world for all.
 
  </p>
    
   
</Accordion.Content>
</Accordion.Panel>

 
    </Accordion>
   </>
  )
}
 