/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
 
export default function Blogsection() {
  return (
   <>
    
    <section>
    <h2 class="w-1/2 p-4 mx-auto text-center text-2xl font-bold sm:text-4xl"> Our Blog </h2>
 
<div className="mt-[-50px] px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <img
            src="./assets/blog/1.webp"
            className="object-contain w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a
                href="/"
                className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                aria-label="Category"
                title="traveling"
              >
               Updated On
              </a>
              <span className="text-gray-600">— February 20th , 2024 </span>
            </p>
            <a
              href="/Blog1"
              aria-label="Category"
              
              className="inline-block mb-3 text-2xl font-bold leading-11 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
            <h1  className="mx-auto text-left  font-bold lg:text-[14px]  sm:text-3xl justify-self-auto " > What " Mahakal Social Welfare Society "  can do For You ? </h1>   
            </a>
            <p className="mb-1 text-gray-700">
            Social welfare societies can do a multitude of things for individuals, depending on the specific organization's focus and resources.. 
            </p>
            <a
  class="mt-8 inline-flex items-center gap-2 rounded border border-indigo-600 bg-orange-500 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Blog1"
>
  <span class="text-sm font-medium"> Learn More </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
          </div>
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <img
            src="./assets/blog/2.webp"
            className="object-contain w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a
                href="/Blog2"
                className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                aria-label="Category"
               
              >
               Update On 
              </a>
              <span className="text-gray-600">— 20th February , 2024</span>
            </p>
            <a
              href="/Blog2"
              aria-label="Category" 
              className="inline-block mb-3 text-2xl font-bold leading-11 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
             <h1  className="mx-auto text-left  font-bold lg:text-[13px]  sm:text-xl justify-self-auto " > Why to Choose "Mahakal Social Welfare Society" for Your Well Life  .  </h1>  
            </a>
            <p className="mb-1 text-gray-700">
            Expertise and Experience: Social welfare organizations specialize in addressing specific social issues and challenges. Extensive knowledge..
            </p>
            <a
  class="mt-8 inline-flex items-center gap-2 rounded border border-indigo-600 bg-orange-500 
  px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Blog2"
>
  <span class="text-sm font-medium"> Learn More </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
          </div>
        </div>
        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <img
            src="./assets/blog/3.webp"
            className="object-contain w-full h-64"
            alt=""
          />
          <div className="p-5 border border-t-0">
            <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a
                href="/"
                className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                aria-label="Category"
                title="traveling"
              >
                Updated On 
              </a>
              <span className="text-gray-600">— 12th February , 2024</span>
            </p>
            <a
              href="/Blog3"
              aria-label="Category" 
              className="inline-block mb-3 text-2xl font-bold leading-11 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
             <h1  className="mx-auto text-left  font-bold lg:text-[14px]  sm:text-xl justify-self-auto " >  Education & Support for  Social Welfare Society When Need. </h1>  
             
            
            </a>
            <p className="mb-2 text-gray-700">
            Social welfare societies offer a diverse range of support to individuals  immediate needs and long-term well-being
            </p>
            <a
  class="mt-8 inline-flex items-center gap-2 rounded border border-indigo-600 bg-orange-500 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Blog3"
>
  <span class="text-sm font-medium"> Learn More </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
          </div>
        </div>
      </div>
    </div>

</section><br></br>
   </>
  )
}
 