/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';
  
export default function Feature() {
  return (
   <> 
 
 <section class="text-gray-600 bg-orange-400 body-font">
<br></br>  <div class="container px-5 py-2 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
      <div class="p-4 sm:w-1/4 w-1/2">
      
     <a href='##' > <Card className=" " imgSrc="./assets/service/Child.webp" >
           
          <h6 className="mb-2 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:20}} className="text-green-600  mt-[2px] fa-solid fa-check-to-slot"></i> <br></br> </span>  Kids & Child WellBeing 
          </h6>  
          </Card></a> 
      </div>

      <div class="p-4 sm:w-1/4 w-1/2">
      
      
     <a href='##' >
     <Card className=" " imgSrc="./assets/service/Mother.webp" >
            
          <h6 className="mb-2 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:20}} className="text-green-600  mt-[2px] fa-solid fa-check-to-slot"></i> <br></br> </span>  Mother Encouragement
          </h6>  
          </Card></a> 
      </div>
      
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='##' > <Card className=" " imgSrc="./assets/service/Education.webp" >
          
          <h6 className="mb-2 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[2px] fa-solid fa-check-to-slot "></i> <br></br> </span>   Education Encouragement
          </h6> 
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='##'  >  <Card className=" " imgSrc="./assets/service/Young.webp" >
           
          <h6 className="mb-2 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[2px] fa-solid fa-check-to-slot"></i> <br></br> </span>   Youngsters Success
          </h6>
          
          </Card> </a><br></br>
      </div>
    </div>
    <br></br>  </div>

 
  <div class="container px-5 py-2 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
      <div class="p-4 sm:w-1/4 w-1/2">

      <a href='##' > 
       <Card className="" imgSrc="./assets/service/Feeding.webp" >
         
          <h6 className="mb-1 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[1px] fa-solid fa-check-to-slot "></i> <br></br> </span>  Feeding For Life 
          </h6>
           
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='##'  >    <Card className=" " imgSrc="./assets/service/Business.webp" >
          
          <h6 className="mb-0 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[1px] fa-solid fa-check-to-slot "></i> <br></br> </span>  Business For Small Sector
          </h6>
         
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='##'  >   <Card className=" " imgSrc="./assets/service/Soical.webp" >
           
          <h6 className="mb-0 text-[11px] lg:text-[10px]  font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[0px] fa-solid fa-check-to-slot "></i> <br></br> </span>  Soical Support For Growth
          </h6> 
          </Card></a>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <a href='##'  >   <Card className=" " imgSrc="./assets/service/Healthcare.webp" >
          
          <h6 className="mb-0 text-[11px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span> <i style={{fontSize:18}} className="text-green-600  mt-[0px] fa-solid fa-check-to-slot"></i> <br></br> </span>  Healthcare around for Life
          </h6>
          
          </Card></a>
      </div>
    </div><br></br>
  </div> 
</section>

 
  </>
  )
}
  