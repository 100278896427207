/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'


export default function Blog3() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-left">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-left  font-bold lg:text-[16px]  sm:text-3xl justify-self-auto " > Education & Support for  Social Welfare Society When Need. </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Here's a breakdown of their support mechanisms:

<br>
</br>
<b> Meeting Basic Needs:</b>

Direct assistance: Providing essential resources like food, shelter, clothing, and hygiene products to individuals and families struggling with poverty, homelessness, or emergencies.
Financial aid: Offering emergency funds, utility assistance, and help with various type.
<br></br>
<b> Healthcare access: </b> Facilitating connections with clinics, hospitals, or insurance programs to ensure individuals receive necessary medical care. professionals to address emotional and psychological challenges.
Empowering Individuals:
<br></br>
<b> Education: </b> Offering scholarships, tutoring programs, and adult education classes to enhance educational opportunities and skills development.
Job training: Providing vocational training, job placement assistance, and workshops . 
          </p>
   
 
   </p>
          </div>
          
        </div>
        <div>
        <div className='player-wrapper'>
        <img src="./assets/blog/3.webp" className="h-210 w-full 
    rounded --300" alt="" /> 
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
