// eslint-disable-next-line no-unused-vars
import React from 'react'
import Iframe from 'react-iframe'
import { useState, useEffect } from "react";
import Skeletonui from '../../Components/Skeletonui';
 /* eslint-disable no-unused-vars */
  export default function Contactsection() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  return (
   <>
  
<section className="bg-white">
  <div className="mx-auto max-w-screen-xl px-4 p-5">
    <div className="grid grid-cols-1 gap-x-16 gap-y-4 lg:grid-cols-5">
      <div className="lg:col-span-2 lg:py-1">
        <p className="max-w-xl text-center text-[16px]">
     Contact us Anytime | Fill Form and Submit 

        </p>
        <ul><br></br>
        <img
          className="object-contain w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src="./assets/media/cc.webp"
          alt=""
        />
<br></br>
  <li>
    <a
      href="tel:917325916209"
      className="flex items-center gap-2 border-s-[3px] border-blue-500 bg-blue-50 px-4 py-3
       text-pink-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z" />
</svg>


      <span className="text-sm font-medium"> Phone :
       <a href="tel:917325916209" className='text-orange-600 text-[14px]' > +91 7325916209 </a> </span>
    </a>
  </li>

  <li>
    <a href="#"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-pink-700 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
</svg>


      <span className="text-sm font-medium"> 
       <a href="#" className='text-orange-600 text-[14px]' >   <address className="mt-2 not-italic"> Raipur, India</address>  </a> </span>
    </a>
  </li>

  <li>
    <a
      href="mailto:contact@mahakalsocialwelfaresociety.com"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3  text-pink-700 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
</svg>


      <span className="text-sm font-medium"> Support Mail  :
   <a href="mailto:contact@mahakalsocialwelfaresociety.com" className='text-orange-600 text-[15px]' > contact@mahakalsocialwelfaresociety.com</a> </span>
    </a>
  </li>
 
 
</ul>
      </div>

      <div className="rounded-lg p-[2px] shadow-lg lg:col-span-3 lg:p-1">
     
      {loading ? <Skeletonui /> :   <Iframe url="https://www.mahakalsocialwelfaresociety.thedigitaldynamo.in/contact_apply/"
        width="100%"
        height="550px" 
        display="block"
        position="relative"/>}
    
      </div>
    </div>
  </div>
</section>
   </>
  )
}
